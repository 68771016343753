export default {
  title: 'Підписки та ціни',
  subtitle: 'Виберіть тариф, який підходить вам найбільше',
  timeline: {
    1: 'Щомісяця',
    3: '3 місяці (до - 10%)',
    6: '6 місяців (до - 20%)',
    12: 'Рік (до - 30%)',
  },
  popular: 'Найпопулярніший',
  days: 'Включаючи дні, що залишилися',
  free: 'Free',
  discount: 'Знижка',
  buttons: {
    promo: 'Використати промокод',
    plan: 'Обрати тариф',
    customPlan: "Зв'язок з менеджером",
  },
  delimetr: {
    days: '/назавжди',
    month: '/місяц'
  }
}