export default {
  params: {
    profiles: 'Profiles',
    availableProxies: 'Proxy Manager',
    patterns: 'Profile Patterns',
    dragAndDropCookies: "Drag'n'drop cookies",
    teamMembers: 'Team Members',
    proxies: 'Saved Proxies',
    apiAccess: 'API Automation',
    unlimitedTags: 'Unlimited Tags',
    transferProfiles: 'Profiles transfer',
    mobileAppAccess: 'Mobile application',
    profilesTransfer: 'Profiles transfer',
    mobileApplication: 'Mobile application',
    unlimitedDevices: 'Unlimited Devices',
    proxyAnalyzer: 'Proxy Analyzer',
    cookiesEditor: 'Cookies Editor',
  },
  popular: 'Most Popular',
  delimetr: {
    days: '/forever',
    month: '/mo'
  },
  custom: 'on request',
}