export default {
  promo: {
    title: 'Использовать промокод',
    text: 'Используйте промокод для получения скидки на выбранный вами тариф',
    input: 'Промокод',
    button: 'Применить',
  },
  deposit: {
    title: 'Пополнить баланс',
    method: 'Метод пополнения',
    amount: 'Сумма в $',
    button: 'Подтвердить',
    options: {
      crypto: 'Crypto',
      card: 'Банковская карта',
      balance: 'Баланс',
    },
  },
  subscription: {
    title: 'Подписка',
    method: 'Выбрать метод',
    total: 'Общая цена',
    free: 'Free',
    week: 'Навсегда',
    button: 'Подтвердить',
    params: {
      user: 'Пользователь',
      subcription: 'Подписка',
      period: 'Период',
      month: 'Цена за месяц',
      price: '/мес',
      chargeback: 'Возвратный платеж на вашу карту',
      cashback: 'Кэшбэк на баланс аккаунта'
    },
    payment: {
      balance: 'Баланс',
      card: 'Карта',
    }
  }
}