export default {
  title: 'Subscriptions and Prices',
  subtitle: 'Choose the tariff that best suits your needs',
  timeline: {
    1: 'Monthly',
    3: '3 Months (up to - 10%)',
    6: '6 months (up to - 20%)',
    12: 'Year (up to - 30%)',
  },
  popular: 'Most Popular',
  days: 'Including remaining days',
  free: 'Free',
  discount: 'Save',
  buttons: {
    promo: 'Use Promocode',
    plan: 'Choose Plan',
    customPlan: 'Contact With Manager',
  },
  delimetr: {
    days: '/forever',
    month: '/month'
  }
}