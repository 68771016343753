export default {
  header: {
    title: 'Анти-детект браузер для мультиакаунтингу',
    upperTitle: 'Працюй швидко & безпечно ',
    text: 'Створюйте тисячі профілів з різними IP і геолокаціями без ризику блокування і 100% проходженням чекерів. Скачайте, зареєструйтесь, працюйте.',
    button: 'Почати',
  },
  browserApp: {
    app: 'Мобільний додаток',
    button: 'Завантажити',
  },
  discount: {
    label: 'Антидетект-браузер #1 - працюйте <span>без витоку даних</span> в афілейт-маркетингу, e-comm та бонусхантінгу',
    title: 'Один дотик до найкращого антидетекту AQUM',
    button: 'Активувати FREE',
  },
  solution: {
    upperTitle: 'Охопіть усі сфери діяльності',
    title: 'Надійне рішення для будь-якого виду бізнесу',
    tiles: {
      1: {
        title: 'Афілейт-маркетинг',
        text: 'Керуйте кількома акаунтами безпечно, уникаючи блокувань з боку платформ і оптимізуйте рекламні стратегії, зберігаючи анонімність',
      },
      2: {
        title: 'Airdrop & Bounty',
        text: 'Використовуйте для створення та управління кількома акаунтами, щоб максимізувати кількість одержуваних токенів або винагород',
      },
      3: {
        title: 'E-Commerce',
        text: 'Масштабуйте бізнес на Amazon, eBay та інших майданчиках - без регіональних обмежень і лімітів на кількість оголошень, акаунтів або товарів',
      },
      4: {
        title: 'Бонусхантінг',
        text: 'Отримуйте максимум від бонусів та акцій, керуючи обліковими записами на різних платформах без блокувань за підозрілу активність і мультиакаунтинг',
      },
      5: {
        title: 'Digital агенції',
        text: 'Використовуйте безліч аканутів, щоб публікувати велику кількість різного контенту для отримання охоплень, а також проводити паралельні кампанії',
      }
    }
  },
  features: {
    upperTitle: 'Чому нас обирають',
    title: 'Наші переваги',
    slider: {
      1: {
        title: 'Drag & Drop Cookies',
        text: 'Завантажуйте файли кукі для моментального заповнення профілів. Якщо знадобиться, відкоригуйте вручну'
      },
      2: {
        title: 'Мобільний додаток',
        text: "Керуйте мультиакаунтингом, де б ви не знаходилися. Обмінюйтеся даними, поки ви в дорозі, на відпочинку і без доступу до основного ПК",
      },
      3: {
        title: 'Робота в команді',
        text: "Передавайте профілі та розподіляйте обов'язки між членами команди, використовуючи систему тегів"
      },
      4: {
        title: 'Canvas',
        text: 'Власна база відбитків на основі реальних даних (без підміни)'
      },
      5: {
        title: 'Розширення',
        text: 'Додаткові можливості антидетект-браузера: можливість завантаження розширень і використання власних рішень'
      },
      6: {
        title: 'Закладки',
        text: 'Налаштовуйте закладки, головну сторінку та інші параметри цифрового відбитка'
      },
    }
  },
  promo: {
    title: 'Спеціальна пропозиція',
    text: 'Протестуйте антидетект-браузер AQUM безкоштовно.  Скористайтеся тарифом FREE з можливістю створення до 5 профілів і додавання 2 проксі до Вибраного',
    button: 'Підписка Free',
  },
  provides: {
    title: 'AQUM - це швидкість і безпека',
    text: 'Підвищуйте безпеку та ефективність з AQUM. Проходження чекерів, надійний захист даних і швидка робота з кількома завданнями одночасно. Почніть просто зараз і отримайте доступ до ексклюзивних можливостей.',
    button: 'Почати',
    tiles: {
      1: 'Одночасні та швидкі процеси',
      2: 'Гарантоване проходження чекерів',
      3: 'Сильна та розширена безпека',
      4: 'Власна служба підтримки',
    }
  },
  testimonials: {
    upperTitle: 'Відгуки',
    title: 'Що користувачі говорять про нас'
  },
  mobileApp: {
    upperTitle: 'Мобільний додаток',
    title: 'AQUM - зручність у смартфоні',
    text: 'Мобільний додаток AQUM є спрощеною версією панелі управління, надаючи цілодобовий доступ до вашого облікового запису та підтримуючи такі функції, як перенесення профілю та управління командою. Додаток також розвивається у напрямку повної функціональності для управління профілем.',
    buttons: {
      google: 'Google Play',
      apple: 'App Store',
    }
  },
  blog: {
    upperTitle: 'AQUM Блог',
    title: 'Блог',
    text: 'Розповідаємо про нові можливості антидетект-браузера AQUM. Ділимося лайфхаками та порадами для користувачів будь-якого рівня',
    button: 'Читати',
  }
}