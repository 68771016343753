export default {
  title: 'Подписки и цены',
  subtitle: 'Выберите тариф, который подходит вам больше всего',
  timeline: {
    1: 'Ежемесячно ',
    3: '3 месяца (до - 10%)',
    6: '6 месяцев (до - 20%)',
    12: 'Год (до - 30%)',
  },
  popular: 'Самый популярный',
  days: 'Включая оставшиеся дни',
  free: 'Free',
  discount: 'Скидка',
  buttons: {
    promo: 'Использовать промокод',
    plan: 'Выбрать тариф',
    customPlan: 'Cвязь с менеджером',
  },
  delimetr: {
    days: '/навсегда',
    month: '/месяц'
  }
}