export default {
  button: 'Головна',
  404: "Вибачте, ми не змогли знайти цю сторінку",
  500: 'Спробуйте перезавантажити сторінку, щоб повторно підключитися до служби',
  schema: {
    pass: 'Пароль має бути щонайменше 8 символів',
    number: 'Пароль повинен містити число',
    upper: 'Пароль має містити велику літеру',
    required: 'Потрібен пароль',
    match: 'Паролі мають збігатися',
    email: {
      req: 'Потрібна електронна адреса',
      invalid: 'Недійсна адреса електронної пошти',
    },
    code: 'Не може бути порожнім',
    telegram: 'Не може бути порожнім',
  },
  changePass: {
    success: 'Пароль успішно змінено',
    incorrect: 'Неправильний пароль',
    lifetime: 'Термін дії посилання минув. Спробуйте ще раз',
  },
  confirmAcc: {
    success: 'Посилання для підтвердження надіслано на електронну пошту',
    verif: 'Не вдалося надіслати посилання для підтвердження. Спробуйте знову',
  },
  resetPass: {
    success: 'Інформацію про скидання пароля надіслано на вказану адресу',
    noUser: 'Не знайдено жодного користувача з цією електронною поштою',
  },
  signIn: {
    incorrect: "Неправильне ім'я користувача або пароль",
  },
  signUp: {
    error: 'Не вдалося зареєструватися. Спробуйте знову',
  },
  download: {
    error: 'Не вдалося отримати актуальне посилання для завантаження програми Windows',
  },
  promocode: {
    promotedBalance: 'На ваш баланс було зараховано {value}$',
    promotedSubscription: '{plan} підписка активована',
    makeDiscount: 'Вартість плану {plan} була знижена на {value}{type}',
    makeTotalDiscount: 'Вартість всіх планів була знижена на {value}{type}',
    extendExpirationFixed: 'Ваша підписка була продовжена на {value}д.',
    extendExpirationPercent: 'Ваша підписка була продовжена на {value}%',
    success: 'Промо-код успішно застосовано до вашого облікового запису.',
    fail: 'Неправильний або прострочений промокод',
  },
  paymentMethod: 'Виберіть спосіб оплати',
};
