export default {
  button: 'Home',
  404: "Sorry, we couldn't find this page",
  500: 'Try reloading the page to reconnect to the service',
  schema: {
    pass: 'Password must be at least 8 characters long',
    number: 'Password must contain a number',
    upper: 'Password must contain an uppercase letter',
    required: 'Password is required',
    match: 'Passwords must match',
    email: {
      req: 'Email is required',
      invalid: 'Invalid email address',
    },
    code: 'Code is required',
    telegram: 'Telegram is required',
  },
  changePass: {
    success: 'The password has been successfully changed',
    incorrect: 'Incorrect password',
    lifetime: 'The link\'s lifetime has expired. Try it again',
  },
  confirmAcc: {
    success: 'The confirmation link has been sent to the email',
    verif: 'Couldn’t send verification link. Try again',
  },
  resetPass: {
    success: 'Password reset information has been sent to the specified address',
    noUser: 'No user with this email was found',
  },
  signIn: {
    incorrect: 'Incorrect username or password',
  },
  signUp: {
    error: 'Couldn’t signup. Try again',
  },
  download: {
    error: 'Couldn`t get an up-to-date link to download the windows app',
  },
  promocode: {
    promotedBalance: '{value}$ has been credited to your balance',
    promotedSubscription: '{plan} subscription has been activated',
    makeDiscount: 'The cost of the {plan} plan has been reduced by {value}{type}',
    makeTotalDiscount: 'The cost of all plans has been reduced by {value}{type}',
    extendExpirationFixed: 'Your subscription has been extended for {value}d.',
    extendExpirationPercent: 'Your subscription has been extended by {value} percent',
    success: 'The promocode has been successfully applied to your account.',
    fail: 'Incorrect or expired promocode',
  },
  paymentMethod: 'Method is required',
};
