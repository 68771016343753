export default {
  params: {
    profiles: 'Профілі',
    availableProxies: 'Менеджер Проксі',
    patterns: 'Шаблони профілів',
    dragAndDropCookies: "Drag'n'drop cookies",
    teamMembers: 'Учасники команди',
    proxies: 'Збережені проксі',
    apiAccess: 'Автоматизація API',
    unlimitedTags: 'Необмежена кількість тегів',
    transferProfiles: 'Передача профілей',
    mobileAppAccess: 'Мобільний додаток',
    profilesTransfer: 'Передача профілей',
    mobileApplication: 'Мобільний додаток',
    unlimitedDevices: 'Необмежена кількість пристроїв',
    proxyAnalyzer: 'Проксі-аналізатор',
    cookiesEditor: 'Редактор файлів cookie',
  },
  popular: 'Найпопулярніший',
  delimetr: {
    days: '/назавжди',
    month: '/місяць'
  },
  custom: 'по запиту',
}