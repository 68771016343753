export default {
  params: {
    profiles: 'Профили',
    availableProxies: 'Менеджер Прокси',
    patterns: 'Шаблоны профилей',
    dragAndDropCookies: "Drag'n'drop cookies",
    teamMembers: 'Участники команды',
    proxies: 'Сохраненные прокси',
    apiAccess: 'Автоматизация API',
    unlimitedTags: 'Неограниченное количество тэгов',
    transferProfiles: 'Передача профилей',
    mobileAppAccess: 'Мобильное приложение',
    profilesTransfer: 'Передача профилей',
    mobileApplication: 'Мобильное приложение',
    unlimitedDevices: 'Неограниченное количество устройств',
    proxyAnalyzer: 'Прокси-анализатор',
    cookiesEditor: 'Редактор файлов cookie',
  },
  popular: 'Самый популярный',
  delimetr: {
    days: '/навсегда',
    month: '/месяц'
  },
  custom: 'по запросу',
}