export default {
  header: {
    title: 'Анти-детект браузер для мультиаккаунтинга',
    upperTitle: 'Работай быстро & безопасно ',
    text: 'Создавайте тысячи профилей с разными IP и геолокациями без риска блокировки и 100% проходжением чекеров. Скачайте, зарегистрируйтесь, работайте.',
    button: 'Начать',
  },
  browserApp: {
    app: 'Мобильное приложение',
    button: 'Скачать',
  },
  discount: {
    label: 'Антидетект-браузер #1 — работайте <span>без утечек данных</span> в аффилиейт-маркетинге, e-comm и бонусхантинге.',
    title: 'Получите доступ к основным функциям бесплатно навсегда!',
    button: 'Активировать FREE',
  },
  solution: {
    upperTitle: 'Охватите все сферы деятельности',
    title: 'Надежное решение для любого вида бизнеса',
    tiles: {
      1: {
        title: 'Аффилейт-маркетинг',
        text: 'Управляйте несколькими аккаунтами безопасно, избегая блокировок со стороны платформ и оптимизируйте рекламные стратегии, сохраняя анонимность',
      },
      2: {
        title: 'Airdrop & Bounty',
        text: 'Используйте для создания и управления несколькими аккаунтами, чтобы максимизировать количество получаемых токенов или вознаграждений',
      },
      3: {
        title: 'E-Commerce',
        text: 'Масштабируйте бизнес на Amazon, eBay и других площадках - без региональных ограничений и лимитов на количество объявлений, аккаунтов или товаров',
      },
      4: {
        title: 'Бонусхантинг',
        text: 'Получайте максимум от бонусов и акций, управляя учетными записями на различных платформах без блокировок за подозрительную активность и мультиаккаунтинг',
      },
      5: {
        title: 'Digital агентства',
        text: 'Используйте множественные аккаунты, чтобы публиковать большое количество различного контента для получения охватов, а также проводить параллельные кампании',
      }
    }
  },
  features: {
    upperTitle: 'Почему нас выбирают',
    title: 'Наши преимущества',
    slider: {
      1: {
        title: 'Drag & Drop Cookies',
        text: 'Загружайте файлы куки для моментального заполнения профилей. Если понадобится, откорректируйте вручную'
      },
      2: {
        title: 'Мобильное приложение',
        text: "Управляйте мультиаккаунтингом, где бы вы ни находились. Обменивайтесь данными, пока вы в дороге, на отдыхе и без доступа к основному ПК",
      },
      3: {
        title: 'Работа в команде',
        text: 'Передавайте профили и распределяйте обязанности между членами команды, используя систему тегов'
      },
      4: {
        title: 'Canvas',
        text: 'Собственная база отпечатков на основе реальных данных (без подмены)'
      },
      5: {
        title: 'Расширения',
        text: 'Дополнительные возможности антидетект-браузера: возможность загрузки расширений и использования собственных решений'
      },
      6: {
        title: 'Закладки',
        text: 'Настраивайте закладки, главную страницу и другие параметры цифрового отпечатка'
      },
    }
  },
  promo: {
    title: 'Специальное предложение',
    text: 'Протестируйте антидетект-браузер AQUM бесплатно.  Воспользуйтесь тарифом FREE с возможностью создания до 5 профилей и добавления 2 прокси в Избранное',
    button: 'Подписка Free',
  },
  provides: {
    title: 'AQUM - это скорость и безопасность',
    text: 'Повышайте безопасность и эффективность с AQUM. Прохождение чекеров, надежная защита данных и быстрая работа с несколькими задачами одновременно. Начните прямо сейчас и получите доступ к эксклюзивным возможностям.',
    button: 'Начать',
    tiles: {
      1: 'Одновременные и быстрые процессы',
      2: 'Гарантированное прохождение чекеров',
      3: 'Сильная и расширенная безопасность',
      4: 'Собственная служба поддержки',
    }
  },
  testimonials: {
    upperTitle: 'Отзывы',
    title: 'Что пользователи говорят о нас'
  },
  mobileApp: {
    upperTitle: 'Мобильное приложение',
    title: 'AQUM - удобство в смартфоне',
    text: 'Мобильное приложение AQUM является упрощённой версией панели управления, предоставляя круглосуточный доступ к вашему аккаунту и поддерживая такие функции, как перенос профиля и управление командой. Приложение постоянно развивается и стремится к полной функциональности для управления профилем.',
    buttons: {
      google: 'Google Play',
      apple: 'App Store',
    }
  },
  blog: {
    upperTitle: 'AQUM Блог',
    title: 'Блог',
    text: 'Рассказываем про новые возможности антидетект-браузера AQUM. Делимся лайфхаками и советами для пользователей любого уровня',
    button: 'Читать',
  }
}