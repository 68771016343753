export default {
  promo: {
    title: 'Use promocode',
    text: 'Use the promocode to get a discount on the tariff of your choice',
    input: 'Promocode',
    button: 'Confirm',
  },
  deposit: {
    title: 'Top up Your Balance',
    method: 'Method',
    amount: 'Amount in U.S. dollar',
    button: 'Confirm',
    options: {
      crypto: 'Crypto',
      card: 'Card',
      balance: 'Balance',
    },
  },
  subscription: {
    title: 'Subscription',
    method: 'Choose Method',
    total: 'Total Price',
    free: 'Free',
    week: 'Forever',
    button: 'Confirm',
    params: {
      user: 'User',
      subcription: 'Subcription',
      period: 'Period',
      month: 'Months price',
      price: '/mo',
      chargeback: 'Chargeback to your card',
      cashback: 'Cashback to your account balance'
    },
    payment: {
      balance: 'Balance',
      card: 'Card',
    }
  }
}